<template>
  <b-overlay variant="light" opacity=".2" blur="5px" :show="show_overlay">
    <div class="login-root" :style="bg_municipio">
      <div class="login-page conteiner-fluid">
        <div class="alert alert-danger" role="alert" v-if="error_message">
          {{ error_message }}
        </div>

        <div class="row m-0">
          <div class="col-12  mt-4 text-center">
            <img src="@/assets/img/gesplanos5.png" class="mt-4 logo-login" />
          </div>
        </div>

        <form class="form-wrap" @submit.prevent="submit()">
          <div class="box-municipio">
            <img :src="$config.login_logo_image" class="mr-4" />
            <div>
              <h2 class="text">{{ $config.login_text }}</h2>
              <h3>{{ $config.login_subtext }}</h3>
            </div>
          </div>
          <div class="form-group">
            <input
              required
              type="email"
              v-model="form.email"
              class="form-control"
              placeholder="E-mail"
            />
          </div>
          <div class="form-group">
            <input
              required
              type="password"
              placeholder="Senha"
              class="form-control"
              v-model="form.password"
            />
          </div>
          <button class="login-button">
            Entrar <span class="material-icons"> arrow_right_alt </span>
          </button>
        </form>
      </div>
      <div class="flex">

      </div>
    </div>
  </b-overlay>
</template>

<script>
//import store from "@/store";
// import logo from "@/assets/img/logo-default.png";
import { mapActions } from "vuex";

export default {
  data: () => ({
    form: {
      email: "",
      password: ""
    },
    error_message: "",
    show_overlay: false
  }),
  computed: {
    to_route() {
      let response = { name: "home" };
      if (this.$route.query.to) {
        let { resolved } = this.$router.resolve(this.$route.query.to);
        if (resolved.name) {
          response = resolved.fullPath;
        }
      }
      return response;
    },
    bg_municipio() {
      return (
        'background-image: url("../../../assets/img/horizontal-azul-login.svg"), \
    url("../../../assets/img/vertical-preto-login.svg"), \
    url("' +
        this.$config.login_bg_image +
        '")'
      );
    }
  },
  methods: {
    ...mapActions("auth", ["ActionDoLogin"]),
    async submit() {
      this.show_overlay = true;
      this.error_message = "";
      try {
        await this.ActionDoLogin(this.form);
        this.$router.push(this.to_route);
      } catch (err) {
        this.error_message =
          err.data && err.data.message
            ? err.data.message
            : "Não foi possível fazer login";
      }
      this.show_overlay = false;
    }
  },
  created() {}
};
</script>

<style scoped lang="scss">
.login-root {
  position: relative;
  z-index: 0;

  .login-button {
    background: none;
    border: none;
    color: white;
    display: flex;
    align-items: center;

    span {
      margin-left: 10px;
    }
  }
}

@media (min-width: 600px) {
  .login-root::before {
    width: 600px;
    left: -100px;
    transform: skewX(-12deg);
  }
}

.form-wrap {
  z-index: 1;
  position: absolute;
  bottom: 100px;
  left: 0;
  width: 100%;
  max-width: 400px;
  padding: 50px;
}

.login-page {
  height: 100vh;
}

.box-municipio {
  color: white;
  text-align: center;
  padding-bottom: 2rem;

  h2 {
    margin-top: 2rem;
    font-size: 18px;
  }

  h3 {
    font-size: 16px;
    font-weight: normal;
  }

  img {
    max-width: 180px !important;
  }
}
</style>
